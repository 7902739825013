<template>
  <mobile-screen
    :header="true"
    screen-class="resources-screen gray-bg icon-app1"
  >
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="resource-header icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: 'r_resource-attributes-help-online'
        }"
      >
        <template v-slot:left>
          <home-button />
        </template>
        <div class="component-title">
          {{
            displayLabelName(
              "resources",
              "resource-attributes",
              "resource-attributes"
            )
          }}
        </div>
        <template v-slot:right>
          <router-link
            v-if="can('MANAGE_RESOURCE_ATTRIBUTES', 'create')"
            :to="{
              name: 'r_add-resource-attribute',
              params: $route.params
            }"
          >
            <icon icon="#cx-hea1-add" />
          </router-link>
        </template>
      </top-header-menu-wrapper>
      <section class="search-wrapper time-zone-search">
        <div class="search-field">
          <input
            type="text"
            @keyup="searchQuery = $event.target.value"
            :placeholder="
              displayLabelName('resources', 'resource-attributes', 'search')
            "
          />
          <div class="icon search">
            <icon icon="#cx-app1-search-16x16" width="16" height="16" />
          </div>
        </div>
      </section>
      <div
        v-if="paginationData && paginationCountActive"
        class="clebex-pagination-count-wrapper"
      >
        <span class="clebex-pagination-count"
          >{{ topScreen }} - {{ bottomScreen }} /
          {{ paginationData.total }}</span
        >
      </div>
    </template>
    <div class="scroll"></div>
    <ul
      class="clebex-item-section pill"
      v-if="listOfResourceAttributes && listOfResourceAttributes.length"
    >
      <li
        class="clebex-item-section-item"
        v-for="resourceAttribute in listOfResourceAttributes"
        :key="resourceAttribute.id"
      >
        <button
          class="clebex-item-content-wrapper"
          v-if="!editing"
          @click="editResourceAttribute(resourceAttribute)"
        >
          <span class="label">
            <span class="highlight">{{ resourceAttribute.name }}</span>
          </span>
          <span class="follow-up-icons">
            <span class="follow-up-icon-item">
              <icon icon="#cx-app1-information"></icon>
            </span>
          </span>
        </button>
        <div class="clebex-item-content-wrapper" v-else>
          <div class="checkbox tiny alt block">
            <input
              type="checkbox"
              :id="`resource-attribute${resourceAttribute.id}`"
              name="user"
              :selected="
                selectedResourceAttributes.includes(resourceAttribute.id)
              "
              :value="resourceAttribute.id"
              @change="selectResourceAttribute(resourceAttribute)"
              :disabled="!can('MANAGE_RESOURCE_ATTRIBUTES', 'delete')"
            />
            <label :for="`resource-attribute${resourceAttribute.id}`">
              <icon icon="#cx-app1-checkmark" />
              <span class="highlight">{{ resourceAttribute.name }}</span></label
            >
          </div>
        </div>
      </li>
    </ul>
    <template v-slot:footer>
      <nav class="actions-menu">
        <ul class="actions-list">
          <li class="action">
            <button
              class="action-btn"
              :class="{ active: editing }"
              @click="setEditing"
            >
              {{
                displayLabelName("resources", "resource-attributes", "select")
              }}
            </button>
          </li>
          <li
            class="action"
            v-if="selectedResourceAttributes.length && editing"
          >
            <button
              class="action-btn"
              :disabled="processing"
              @click="show = true"
            >
              <icon icon="#cx-app1-delete-02-14x14" />
            </button>
            <span class="action-btn deleted-count">{{
              "(" +
                selectedResourceAttributes.length +
                "/" +
                listOfResourceAttributes.length +
                ")"
            }}</span>
          </li>
        </ul>
      </nav>
    </template>
    <screen-modal
      v-if="show"
      class="confirm-modal"
      type="success"
      :confirm-action="startDelete"
      :confirm-button-label="
        displayLabelName(
          'resources',
          'resource-attributes',
          'delete-confirm-action'
        )
      "
      :cancelButtonLabel="
        displayLabelName(
          'resources',
          'resource-attributes',
          'delete-cancel-action'
        )
      "
      :show="show"
      @close="show = false"
    >
      <h3 class="modal-title">
        {{
          displayLabelName("resources", "resource-attributes", "delete-title")
        }}
      </h3>
      <p class="text">
        {{
          displayLabelName(
            "resources",
            "resource-attributes",
            "delete-action-text"
          )
        }}
        <br />
        {{
          displayLabelName(
            "resources",
            "resource-attributes",
            "delete-action-question"
          )
        }}
      </p>
    </screen-modal>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";
import paginationMixin from "@/services/mixins/pagination/pagination-mixin";

export default {
  name: "ResourceAttributes",
  mixins: [helpOnlineMixin, paginationMixin],
  data() {
    return {
      processing: false,
      show: false,
      searchQuery: "",
      editing: false,
      helpSlug: "resource-attributes",
      selectedResourceAttributes: []
    };
  },
  computed: {
    ...mapState("resource", [
      "resourceAttributes",
      "selectedResourceAttribute"
    ]),
    listOfResourceAttributes() {
      const query = this.searchQuery;
      if (this.resourceAttributes && this.resourceAttributes.length) {
        if (query && query.length > 1) {
          return this.resourceAttributes.filter(item =>
            item.name.toLowerCase().includes(query.toLowerCase())
          );
        } else {
          return this.resourceAttributes;
        }
      }
      return null;
    }
  },
  created() {
    this.setPaginationData(null);
    this.getResourceAttributes();
  },
  methods: {
    ...mapActions("resource", [
      "getResourceAttributes",
      "setSelectedResourceAttribute",
      "deleteResourceAttribute"
    ]),
    ...mapActions("pagination", ["setPaginationData"]),
    setEditing() {
      this.setSelectedResourceAttribute(null);
      this.editing = !this.editing;
      this.$router.push({ name: "r_resource-attributes" });
    },
    editResourceAttribute(resourceAttribute) {
      if (this.can("MANAGE_RESOURCE_ATTRIBUTES", "update")) {
        setTimeout(() => {
          this.$router.push({
            name: "r_edit-resource-attribute",
            params: { resource_attribute_id: resourceAttribute.id }
          });
        }, 0);
      }
    },
    selectResourceAttribute(resourceAttribute) {
      if (this.selectedResourceAttributes.includes(resourceAttribute.id)) {
        this.selectedResourceAttributes.splice(
          this.selectedResourceAttributes.indexOf(resourceAttribute.id),
          1
        );
      } else {
        this.selectedResourceAttributes.push(resourceAttribute.id);
      }
    },
    async startDelete() {
      this.processing = true;
      for (var i = 0; i < this.selectedResourceAttributes.length; i++) {
        await this.deleteResourceAttribute(this.selectedResourceAttributes[i]);
      }
      this.setPaginationData(null);
      this.processing = false;
      this.setSelectedResourceAttribute(null);
      this.editing = false;
      this.selectedResourceAttributes = [];
      this.$nextTick(() => {
        this.$router.push({ name: "r_resource-attributes" });
      });
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  }
};
</script>
<style>
.deleted-count {
  position: relative;
  top: -4px;
  margin: 5px 8px;
  padding-left: 0px !important;
  margin-left: 0px;
}
</style>
